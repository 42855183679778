<template>
  <el-dialog
    top="150px"
    width="500px"
    class="upload-audio"
    title="'Chỉnh sửa thông tin audio"
    :visible="visible"
    :close-on-click-modal="false"
    append-to-body
    @close="handleClosePopup"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên audio"></el-input>
      </el-form-item>
      <el-form-item label="Tác giả" prop="name">
        <el-input v-model="form.author" :disabled="callingAPI" placeholder="Nhập vào tên tác giả"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClosePopup">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateAudio">Update</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateStorage } from '@/services/storage'
export default {
  name: 'UpdateAudio',
  props: {
    visible: Boolean,
    type: String,
    audio: Object,
    object: Object
  },
  data() {
    return {
      uploading: false,
      callingAPI: false,
      audioInfo: JSON.parse(JSON.stringify(this.audio || null)),
      form: JSON.parse(JSON.stringify(this.audio)),
      rules: {
        name: [
          {
            required: true,
            message: 'Title không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    handleUpdateAudio() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateStorage(this.form).then(() => {
            this.callingAPI = false
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật tên Audio thành công.'
            })
            this.$emit('reload')
            this.$emit('close')
          }).catch(() => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể cập nhật Audio.'
            })
          })
        }
      })
    },
    handleClosePopup() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">
.upload-audio {
  .el-upload-dragger {
    width: 160px;
    height: 120px;
    i {
      margin: 15px;
    }
  }
  .card-audio {
    width: 48%;
    box-sizing: border-box;
  }
}
</style>
