<template>
  <div>
    <el-upload
      class="file-uploader"
      action
      :accept="`.json`"
      :show-file-list="false"
      :http-request="uploadFiles"
    >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload"
        :loading="callingAPI"
        :disabled="disabled"
      >
        {{ showText ? 'Import File Urls' : '' }}
      </el-button>
    </el-upload>
    <import-file-urls-popup
      v-if="visible"
      :visible="visible"
      :type="type"
      :total="total"
      :columns="columns"
      :data="fileUrlsData"
      @reload="() => $emit('reload')"
      @close="visible = false"
    />
  </div>
</template>

<script>
import ImportFileUrlsPopup from '../popups/ImportFileUrls.vue'

export default {
  name: 'ImportFileUrlsButton',
  components: {
    ImportFileUrlsPopup
  },
  props: {
    total: Number,
    disabled: Boolean,
    columns: Array,
    type: String,
    showText: {
      type: Boolean,
      default: true
    },
    activeTab: [String, Boolean]
  },
  data() {
    return {
      visible: false,
      callingAPI: false,
      fileUrlsData: []
    }
  },
  computed: {},
  methods: {
    async uploadFiles(fileInfo) {
      const file = fileInfo.file
      this.callingAPI = true
      const reader = new FileReader()
      const self = this
      reader.onload = function(e) {
        const data = e.target.result
        self.fileUrlsData = JSON.parse(data)
        self.visible = true
        self.callingAPI = false
      }

      reader.onerror = function(ex) {
        console.log(ex)
      }

      reader.readAsText(file)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
